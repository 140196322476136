html {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.login {
  background: url("../src/assets/images/bgImg.png") no-repeat center center fixed;
  -webkit-background-size: co1ver;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#error-page {
  background: url("../src/assets/images/404-page.svg") no-repeat center center fixed;
  -webkit-background-size: co1ver;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100dvh;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#btn {
  background-color: #1c87c9;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  border: none;
  color: #eeeeee;
  display: inline-block;
  font-size: 20px;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
}

@keyframes glowing {
  0% {
    background-color: #1c87c9;
    box-shadow: 0 0 5px #1c87c9;
  }

  50% {
    background-color: #1c87c9;
    box-shadow: 0 0 20px #1c87c9;
  }

  100% {
    background-color: #1c87c9;
    box-shadow: 0 0 5px #1c87c9;
  }
}

#btn {
  animation: glowing 1300ms infinite;
}